import React from 'react';

import { OneColumn } from '@components/Layouts/OneColumn';
import NotFound from '@components/Routing/NotFound';
import KoreaRRNForm from '@presenters/web/pages/OnlineGiving/KoreaRRNForm/KoreaRRNForm';

import { FEATURE_MY_DONATIONS_GOVERNMENT_ID, isEnabled } from '@utils/features';

const GovernmentIdKorRRNPage: React.FC = () => {
  const isGovernmentIdEnabled = isEnabled(FEATURE_MY_DONATIONS_GOVERNMENT_ID);
  if (!isGovernmentIdEnabled) {
    return <NotFound />;
  }
  return (
    <OneColumn>
      <KoreaRRNForm />
    </OneColumn>
  );
};
export default GovernmentIdKorRRNPage;
